.contact-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}


.contact-social {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

input {
    box-sizing: border-box;
    padding-top: 12px;
    height: auto;
    width: 90%;
    padding: 12px 12px;
    resize: none;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 8px;
}


textarea {
    box-sizing: border-box;
    height: 150px;
    width: 90%;
    padding: 12px 12px;
    border: 1px solid #ccc;
    resize: none;
    border-radius: 8px;
    background-color: #f8f8f8;
}

.form-input {
    margin-bottom: 10px;
}

.email-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    font-size: 18px;
}

.mail-header {
    margin-top: 50px;
    color: blueviolet;
    size: 40px;
}
.send-button{
    padding-bottom: 1cm;
}

@media (max-width: 1165px) {
    .contact-page{
        margin-left: 10%;
        max-width: 80%;
    }
    .contact{
        margin-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .projects-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    textarea{
        height: fit-content;
    }
    .contact Button {
        margin-bottom: 2cm;
    }
}