.github-calendar-container {
  margin: 0 auto;
  height: max-content;
  padding: 20px 20px;
  background: #9370db;
  color: white;
  font-weight: bold;
  max-width: auto;
}

@media only screen and (max-width: 600px) {
  .github-calendar-container {
    max-width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 20px;
  }
}