.hello {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    height: 92vh;
}


.social {
    display: flex;
    flex-direction: row;
    width: 400px;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin-top: 30px;
}

.mail {
    font-size: 24px;
    height: 30px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 1cm;
}

.photo {
    margin-bottom: 250px;
    width: 550px;
    height: 550px;
    border: 8px solid #9370db;
    border-radius: 100%;
}

.cv-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

.title {
    padding-top: 10px;
    color: grey;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: auto;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-body img {
    width: 100%;
    border-radius: 8px;
}

.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    cursor: pointer;
}

.modal-close-btn:hover {
    color: blue;
}

.modal-body button {
    background-color: red;
    padding: 4px;
    color: white;
    border-radius: 100%;
}



/* home.css */

@media (max-width: 918px) {
    .hello {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .social {
        flex-wrap: wrap;
    }

    .mail {
        margin-bottom: 20px;
    }

    .photo {
        width: 350px;
        height: 350px;
        margin: 10px 0;
    }

    .cv-button {
        margin-top: 30px;
    }

    .text {
        align-items: center;
        width: 100%;
    }
}