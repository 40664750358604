.section-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 1700px;
    max-width: 95%;
}

.about-me {
    display: flex;
    flex-direction: column;
    width: 1700px;
    max-width: 95%;
    font-size: 16px;
    flex-wrap: wrap;
}

.section-2 h2 {
    color: blueviolet;
    font-size: 32px;
}

.education {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.experience {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    width: 1700px;
    flex-wrap: wrap;
}

.experience-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 160px;
}

.experience h2 {
    padding-bottom: 16px;
    color: blueviolet;
}

.experience-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 1cm;
}

.experience-item p {
    width: 80%;
}

.experience-logo {
    width: auto;
    height: 125px;
    margin-right: 16px;
}

.tarsus {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
}

.tarsus-logo {
    width: 125px;
    height: 125px;
    margin-right: 10px;
}

@media (max-width: 1165px) {
    .experience-row {
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .experience-item {
        margin-right: 0px;
        width: 90%;
    }

    .experience-item p {
        width: 220px;
        padding-bottom: 4px;
    }
}